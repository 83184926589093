import clsx from 'clsx';
import Typography from '@ui/Typography';
import { t } from '@lib/i18n';
import Slider from '@ui/Slider';
import { SliderCustomButton } from '@ui/SliderCustomButton';
import { useMediaQuery } from '@hooks/useMediaQuery';
import Image from '@ui/Image';
import ItemsTicker from '@ui/ItemsTickerNew';
import AwardIcon from '../../../../../static/images/main-page/award.svg';

import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.rewards.${key}`);
const items = tr('items');

const colors = {
  teal: {
    stroke: 'stroke-teal400',
    ribbon: 'bg-teal400',
    ribbon_back: 'bg-teal500',
  },
  orange: {
    stroke: 'stroke-orange400',
    ribbon: 'bg-orange400',
    ribbon_back: 'bg-orange500',
  },
  cyan: {
    stroke: 'stroke-cyan400',
    ribbon: 'bg-cyan400',
    ribbon_back: 'bg-cyan500',
  },
  yellow: {
    stroke: 'stroke-yellow400',
    ribbon: 'bg-yellow400',
    ribbon_back: 'bg-yellow500',
  },
  purple: {
    stroke: 'stroke-purple400',
    ribbon: 'bg-purple400',
    ribbon_back: 'bg-purple500',
  },
};

const Card = ({ item }) => {
  const {
    logo, place, category, company, award, year, color, text_color, font,
  } = item;

  return (
    <div className={clsx('relative mx-4 h-[362px] w-[304px] px-3 pt-2 text-center', colors[color].stroke)}>
      <AwardIcon className="absolute inset-0 -z-10" />
      <Image src={`/images/client-logos/${logo}.webp`} alt={`${logo}_logo`} className="mx-auto h-5" />
      <div className="mt-2 flex h-[84px] flex-col justify-between">
        <Typography color="secondary200" className="text-2xs">{place}</Typography>
        <Typography weight="medium" className="text-xl">{category}</Typography>
      </div>

      <div className="relative mt-4 -ml-4 w-fit">
        <div className={clsx(
          'h-5 w-[320px] rounded-t-[4px] [clip-path:polygon(0_0,100%_0,99.5%_100%,0.5%_100%)]',
          colors[color].ribbon,
          'flex items-center justify-center',
        )}
        >
          <Typography weight="medium" className={clsx('uppercase', font && 'text-2xs', text_color && 'text-white')}>{company}</Typography>
        </div>
        <div className={clsx('absolute bottom-[-9px] left-[2px] h-[9px] w-[14px] [clip-path:polygon(0_0,100%_0,100%_100%,100%_100%)]', colors[color].ribbon_back)} />
        <div className={clsx('absolute bottom-[-9px] right-[2px] h-[9px] w-[14px] [clip-path:polygon(0_0,100%_0,0_100%,0_100%)]', colors[color].ribbon_back)} />
      </div>
      
      <Typography className="mt-4 text-2xs">{award}</Typography>
      <Typography weight="medium" className="text-xl">{year}</Typography>
    </div>
  );
};
const itemComponents = items.map((item, index) => (
  <Card item={item} key={index} />
));

export const Rewards = () => {
  return (
    <section className="mx-3 bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)] py-8">
      <div className="grid-layout mx-auto">
        <BlockCaption>{tr('caption')}</BlockCaption>
        <Typography variant="header2" className="mt-4 text-center">{tr('title')}</Typography>
      </div>

      <div className="-mx-3 mt-9">
        {/* <Slider
          settings={{
            slidesToShow: 4,
            arrows: !isMobile,
            nextArrow: <SliderCustomButton addClassName="!pr-[8px] !pl-[12px] md:!pr-[5px] md:!pl-[7px] !right-[-60px]" />,
            prevArrow: <SliderCustomButton addClassName="!pr-[12px] !pl-[8px] md:!pl-[5px] md:!pr-[7px] !left-[-60px]" />,
            responsive: [
              {
                breakpoint: 1365,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                },
              },
            ],
            dots: isMobile,
          }}
          gap={4}
        >
          {items.map((item, index) => (
            <Card item={item} key={index} />
          ))}
        </Slider> */}

        <ItemsTicker
          tickerProps={{
            autoFill: true, height: 362, pauseOnHover: true, 
          }}
          className=""
          items={itemComponents}
        />
      </div>
    </section>
  );
};
