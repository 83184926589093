import { Disclosure, Transition } from '@headlessui/react';
import StaticIcon from '@ui/StaticIcon';
import Typography from '@ui/Typography';
import clsx from 'clsx';

const Divider = ({ className }) => {
  return (
    <div className={clsx('!my-0 h-[1px] w-full bg-tertiary300', className)} />
  );
};

const Accordion = ({
  items,
  className,
}) => {
  return (
    <div className={clsx(className, 'flex flex-col space-y-2')}>
      {
        items.map((item, i) => {
          return (
            <Disclosure key={item.title}>
              {
                ({ open }) => (
                  <>
                    <Disclosure.Button
                      className="!mt-0 flex cursor-pointer items-center justify-between border-0 bg-transparent py-[3px] px-0 text-left"
                    >
                      <Typography variant="header3" className="font-semibold" plainText>
                        {item.title}
                      </Typography>
                      <StaticIcon name="chevron-down" className={open && 'rotate-180'} />
                    </Disclosure.Button>
                    <Divider className={i === items.length - 1 && 'hidden'} />
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="py-1 pb-[20px]">
                        {item.renderContent()}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )
              }
            </Disclosure>
          );
        })
      }
    </div>
  );
};

export default Accordion;
